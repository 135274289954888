import styled, { css } from 'styled-components';
import { Colors, Media } from 'config/CONSTANTS';

type ItemProps = {
  boxshadow?: boolean;
  hovered?: boolean;
};

type ContainerItemsProps = {
  center?: boolean;
};

const paragraphStyle = 'margin-top: 20px;';
const contentWidth = `
  @media (min-width: ${Media.TABLET}) {
    padding-right: 25px;
  }
  @media (min-width: 1440px) {
    width: 85%;
    padding-right: 0;
  }
`;

export const ContainerItems = styled.div`
  width: 100%;

  @media (min-width: ${Media.MOBILE}) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  @media (min-width: ${Media.TABLET}) {
    height: 60vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 1px !important;
      background: ${Colors.PRIMARY};
      height: 30px;
      display: block;
    }

    &::-webkit-scrollbar-track {
      background-color: ${Colors.GRAY};
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${Colors.PRIMARY};
      height: 20px;
      width: 3px;
    }
  }

  ${({ center }: ContainerItemsProps) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
      overflow-y: hidden;
      margin-top: 0;
    `}
`;

export const ContainerItemsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -10px;
  width: 100%;
  padding-top: 20px;

  @media (min-width: ${Media.TABLET}) {
    margin: -25px;
  }
`;

export const Item = styled.div`
  width: 50%;
  display: table;
  padding: 10px;
  text-align: center;

  .gatsby-image-wrapper {
    height: auto;
  }

  a {
    display: block;
    pointer-events: none;
    cursor: pointer;
    &::before {
      content: none;
    }
    &:hover {
      opacity: 1;
    }
  }

  @media (min-width: ${Media.TABLET}) {
    padding: 25px;
    width: 33.33%;
  }

  ${({ boxshadow }: ItemProps) =>
    boxshadow &&
    css`
      .gatsby-image-wrapper {
        box-shadow: 10px 10px 30px rgba(20, 23, 28, 0.2);
        transition: box-shadow 0.3s ease;
      }
    `}

  ${({ hovered }: ItemProps) =>
    hovered &&
    css`
      a {
        pointer-events: all;
        &:hover {
          opacity: 0.8;
          .gatsby-image-wrapper {
            box-shadow: 20px 20px 40px rgba(20, 23, 28, 0.3);
          }
        }
      }
    `}
`;

export const ContainerImgWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  max-width: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;

  img {
    width: 100%;
    border-radius: 20px;
  }

  @media (min-width: ${Media.MOBILE}) {
    left: 0;
    transform: none;
    max-width: 200px;
    max-height: 285px;
  }
`;

export const ContainerContent = styled.div`
  position: relative;
  padding-top: 130px;
  ${contentWidth};
  p {
    &:not(:first-child) {
      ${paragraphStyle}
    }
  }

  @media (min-width: ${Media.MOBILE}) {
    padding-left: 250px;
    padding-top: 0;
    min-height: 300px;
  }
`;

export const ContainerDescription = styled.div`
  margin-top: 30px;
  ${contentWidth};
  p {
    &:not(:first-child) {
      ${paragraphStyle}
    }
  }
`;

export const ContainerLinksWrapper = styled.div`
  margin-top: 40px;
`;
