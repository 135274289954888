import styled, { css } from 'styled-components';
import { Font, Colors } from 'config/CONSTANTS';
import { rem } from 'polished';

type SectionTitleProps = {
  spaces?: boolean;
};

export const SectionTitle = styled.span`
  font-family: ${Font.QUOTES};
  font-weight: ${Font.WEIGHTBOLD};
  font-size: ${rem('14px')};
  line-height: ${rem('19px')};
  color: ${Colors.PRIMARY};

  ${({ spaces }: SectionTitleProps) =>
    spaces &&
    css`
      margin-top: 20px;
      display: block;
    `}
`;
