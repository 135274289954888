import { graphql, useStaticQuery } from 'gatsby';

export const getDefaultIndex = (): number => {
  const isBrowser = typeof window !== `undefined`;

  if (isBrowser) {
    const hashIndex = window.location.hash.slice(1);
    const hashIndexNumber = parseInt(hashIndex, 10);

    return !isNaN(hashIndexNumber) ? hashIndexNumber : 0;
  }

  return 0;
};

export const useBooksQuery = () => {
  const { wordpressAcfPages } = useStaticQuery(
    graphql`
      query BooksQuery {
        wordpressAcfPages(wordpress_id: { eq: 21 }) {
          acf {
            books {
              trailer
              review
              bookstore
              background_image {
                id
                source_url
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              pl {
                quotation
                description
                title
                cover {
                  source_url
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              en {
                quotation
                description
                title
                cover {
                  source_url
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return wordpressAcfPages.acf.books;
};
