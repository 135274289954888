import React from 'react';
import Img from 'gatsby-image';
import { Link, useIntl } from 'gatsby-plugin-intl';
import PageTemplate from 'templates/PageTemplate';
import { Helmet } from 'react-helmet';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import { ContainerItems, ContainerItemsRow, Item } from 'components/ContainerItems/ContainerItems';
import { SectionTitle } from 'components/SectionTitle/SectionTitle';
import { useBooksQuery } from 'hooks/use-books-query';
import HeroImage from '../components/HeroImage/HeroImage';
import { HeroSectionImage } from '../components/HeroImage/HeroImageStyles';
import * as Styled from '../components/Quote/QuoteStyles';
import QuoteIcon from '../assets/quote.svg';
import { ContentRow, ContentWrapper } from '../components/SectionContent/SectionContentStyles';
import { useImageQuery } from '../hooks/use-image-query';

const Books: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const books = useBooksQuery();
  const dataImage = useImageQuery();
  const { fluid } = dataImage.books_bg.localFile.childImageSharp;
  return (
    <PageTemplate>
      <HeroImage>
        <HeroSectionImage>
          <Img fluid={fluid} />
        </HeroSectionImage>
        <Styled.QuoteWrapper>
          <QuoteIcon />
          <Styled.QuoteContent notAnimate>{formatMessage({ id: 'quote.title' })}</Styled.QuoteContent>
        </Styled.QuoteWrapper>
      </HeroImage>
      <ContentWrapper>
        <ContentRow top>
          <Helmet htmlAttributes={{ lang: `${locale}` }}>
            <title>Agnieszka Kazmierczyk - Książki</title>
            <meta name="description" content="Some content." />
          </Helmet>
          <SectionHeading>{formatMessage({ id: 'books.title' })}</SectionHeading>
          <ContainerItems>
            <ContainerItemsRow>
              {books.map((book: object, index: number) => {
                const { fluid } = book[locale].cover.localFile.childImageSharp;
                const { alt_text } = book[locale].cover;
                const { title } = book[locale];
                const { bookstore }: { bookstore?: string } = book;

                return (
                  <Item key={index} boxshadow hovered={bookstore ? true : false}>
                    <Link to={`/`} state={{bookId: index}}>
                      <Img fluid={fluid} alt={alt_text} />
                      <SectionTitle spaces>{title}</SectionTitle>
                    </Link>
                  </Item>
                );
              })}
            </ContainerItemsRow>
          </ContainerItems>
        </ContentRow>
      </ContentWrapper>

    </PageTemplate>
  );
};

export default Books;
